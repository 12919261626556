import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import CountryService from "./app-country-service";
import commonAPIService from "../../common/js/common-api-service";
export default {
  data: () => ({
    name: "Country",
    mainPage: "",
    subPage: "",
    pageUrl: "",
    popupTitleParent: "",
    workcenter: true,
    backSlash: true,
    selectedProject: "",
    selectedCountry: "",
    favouriteList: [],
    showAdd: false,
    projectList: [],
    countryList: [],
    userId: EncryptUtility.localStorageDecrypt("userID"),
    projectSelected: true,
    refresh: true,
    countryTable: false,
    ProjectTbl: false,
    totalRecords: 0,
    countryProjectList: [],
    itemStatusDialog: false,
    headers: [
      {
        text: "Active",
        align: "start",
        value: "active",
        width: "30%",
        class: "primary customwhite--text",
      },
      {
        text: "Country",
        value: "country",
        width: "70%",
        class: "primary customwhite--text",
      },
    ],
    projectheaders: [
      {
        text: "Active",
        align: "start",
        value: "active",
        width: "30%",
        class: "primary customwhite--text",
      },
      {
        text: "Project",
        value: "project",
        width: "70%",
        class: "primary customwhite--text",
      },
    ],
    editArray: [],
    countryId: 0,
    projectId: 0,
    countryProjectSearchList: [],
  }),
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  mounted() {
    this.getProject();
    this.getCountry();
  },
  methods: {
    //Project Details
    async getProject() {
      let projectData = await CountryService.getProject(this.userId);
      this.projectList = projectData.ProjectInfo;
    },
    //Project Details
    async getCountry() {
      let countryData = await commonAPIService.countryListNew(this.userId, "", 1, "get");
      this.countryList = countryData.CountryInfo;
    },
    //Grid Bind
    async countrySearch() {
      this.countryProjectList = [];
      let searchObj = {
        UserID: parseInt(this.userId),
        Pass: this.selectedCountry == "" ? 1 : 2,
        ProjId: this.selectedProject == "" ? 0 : parseInt(this.selectedProject),
        CountryId: this.selectedCountry == "" ? 0 : parseInt(this.selectedCountry),
      };
      let searchData = await CountryService.postCountrySearch("post", searchObj);
      if (searchData.message != "NA") {
        if (this.selectedCountry == "") {
          this.countryProjectList = searchData.map((x) => ({
            active: x.active,
            country: x.country,
            country_key: x.country_id,
            project_id: x.proj_key,
          }));
          let usaIndex = this.countryProjectList.findIndex(country => country.country === "USA");
          // Check if USA is active and move it to the top if it is
          if (usaIndex !== -1 && this.countryProjectList[usaIndex].active === 1) {
              let usa = this.countryProjectList.splice(usaIndex, 1)[0]; // Remove USA from the array
              this.countryProjectList.unshift(usa); // Add USA to the top of the array
          }

          this.countryTable = true;
        } else {
          this.countryProjectList = searchData.map((x) => ({
            active: x.active,
            country_key: x.country_key,
            project_id: x.project_id,
            project: x.project,
          }));
          this.ProjectTbl = true;
        }
      } else {
        this.countryProjectList = [];
      }
      this.totalRecords = this.countryProjectList.length;
    },
    //Grid Bind
    async countrySearchNoLoader() {
      let searchObj = {
        UserID: parseInt(this.userId),
        Pass: this.selectedCountry == "" ? 1 : 2,
        ProjId: this.selectedProject == "" ? 0 : parseInt(this.selectedProject),
        CountryId: this.selectedCountry == "" ? 0 : parseInt(this.selectedCountry),
      };
      let searchData = await CountryService.postCountrySearchNoLoader("post", searchObj);
      if (searchData.message != "NA") {
        if (this.selectedCountry == "") {
          this.countryProjectList = searchData;
          this.countryTable = true;
        } else {
          this.countryProjectList = searchData;
          this.ProjectTbl = true;
        }
      } else {
        this.countryProjectList = [];
      }
      this.totalRecords = this.countryProjectList.length;
    },
    //Get Page Action and name
    //On Click of Favorite icon
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //To set the action
    actionSetter(action) {
      if (this.actionList !== null && this.actionList.length > 0) {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
    //Project Selected
    onProjectSelect() {
      if (this.selectedProject !== null && this.selectedProject !== undefined) {
        this.ProjectTbl = false;
        this.selectedCountry = "";
        this.countryProjectSearchList = "";
        this.countrySearch();
      } else {
        this.resetFunction();
      }
    },
    //On country Select
    onCountrySelect() {
      if (this.selectedCountry !== null && this.selectedCountry !== undefined) {
        this.countryTable = false;
        this.selectedProject = "";
        this.countryProjectSearchList = "";
        this.countrySearch();
      } else {
        this.resetFunction();
      }
    },
    //Reset Function
    resetFunction() {
      this.selectedCountry = "";
      this.selectedProject = "";
      this.projectList = [];
      this.countryList = [];
      this.getProject();
      this.getCountry();
      this.countryProjectList = [];
      this.totalRecords = 0;
      this.countryTable = false;
      this.ProjectTbl = false;
      this.itemStatusDialog = false;
    },
    //Popup event
    changeProjectCountry(item) {
      this.editArray = [];
      this.editArray = item;
      if (item.active == false) {
        this.popupTitleParent = "Are you sure you want to disable this item?";
      } else if (item.active == true) {
        this.popupTitleParent = "Are you sure you want to enable this item?";
      }
      this.itemStatusDialog = true;
    },
    //Status Change of Popup
    itemStatusChange() {
      this.editCountryProject(this.editArray);
    },
    //On Cancel on popup
    onCancel() {
      this.itemStatusDialog = false;
      this.countrySearch();
    },
    //Update the status
    editCountryProject(item) {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      if (this.selectedCountry != "") {
        this.countryId = this.selectedCountry;
      }
      if (this.selectedProject != "") {
        this.projectId = this.selectedProject;
      }
      let EditObject = {
        UserID: parseInt(this.userId),
        ProjId: item.project_id === 0 || typeof item.project_id === "undefined" ? this.projectId : item.project_id,
        CountryId: item.country_key === 0 || typeof item.country_key === "undefined" ? this.countryId : item.country_key,
        Active: item.active == true ? 1 : 0,
      };
      this.axios
        .post("/cp/country_project_update", EditObject)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let Alert = {
              type: "success",
              isShow: true,
              message: response.data.body.message,
            };
            this.countrySearch();
            this.$store.commit("ConfigModule/Alert", Alert);
            this.itemStatusDialog = false;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
  },
  components: {
    breadcrumbComp,
  },
};
